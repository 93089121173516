import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import ProjectsSection from './components/ProjectsSection/ProjectsSection';
import ResumeSection from './components/Resume/Resume'; // Make sure to create this component
import Home from './components/Home/Home';
import Contact from './components/Contact/Contact';
import RelatedNews from './components/RelatedNews/RelatedNews';
import NotFound from './components/NotFound/NotFound';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<ProjectsSection />} />
          <Route path="/related" element={<RelatedNews />} />
          <Route path="/resume" element={<ResumeSection />} />
          <Route path='/contact' element={<Contact />} /> 
          {/* Add more routes as needed */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
