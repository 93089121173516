import React from 'react';
import './RelatedNews.css';

// Example data structure for news articles, including URLs
const newsArticles = [
  {
    id: 1,
    source: 'MalagaHoy',
    title: 'El mejor ingeniero de software joven es malagueño y trabaja en Aston Martin',
    timeAgo: '30/09/2023',
    imageUrl: 'https://www.malagahoy.es/2023/09/29/malaga/Enrique-Martin-Ocana-Software-Engineer_1834327033_193607849_667x375.jpg', // Replace with your image path
    url: 'https://www.malagahoy.es/malaga/mejor-ingeniero-software-malagueno-Aston-Martin_0_1834317520.html' // Replace with the actual article URL
  },
  {
    id: 2,
    source: 'Diario Sur',
    title: '¿Cómo acaba un malagueño siendo el Joven Ingeniero del Año en Escocia?',
    timeAgo: '30/09/2023',
    imageUrl: 'https://s3.ppllstatics.com/diariosur/www/multimedia/2023/09/29/traje-RcYzaSZqE9bN6rGvfgZazlN-1200x840@Diario%20Sur.jpg', // Replace with your image path
    url: 'https://www.diariosur.es/tecnologia/acaba-elegido-malagueno-joven-ingeniero-ano-escocia-20230929191459-nt.html' // Replace with the actual article URL
  }
  // ... add more articles as needed
];

const RelatedNews = () => {
  return (
    <div className="related-news">
      {newsArticles.map(article => (
        <a 
          key={article.id} 
          href={article.url} 
          target="_blank" 
          rel="noopener noreferrer"
          className="news-article-link"
        >
          <div className="news-article">
            <img src={article.imageUrl} alt={article.source} className="news-image" />
            <div className="news-content">
              <h2 className="news-title">{article.title}</h2>
              <p className="news-time">{article.timeAgo}</p>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

export default RelatedNews;
