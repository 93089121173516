import React from 'react';
import './ProfileSection.css';
import profileImage from '../../assets/quique.jpg'; // Main image import
import smallImage2 from '../../assets/mickey.jpg'; // Import your smaller images
import smallImage1 from '../../assets/sant.jpg';
import smallImage3 from '../../assets/sis.jpg';
import smallImage4 from '../../assets/aston.jpg';

const ProfileSection = () => {
  return (
    <div className="profile-section">
      <div className="hover-area">
        <div className="image-container">
          <div className="small-image-container left-1">
            <img src={smallImage1} alt="Small Image 1" className="small-image"/>
          </div>
          <div className="small-image-container left-2">
            <img src={smallImage2} alt="Small Image 2" className="small-image"/>
          </div>
          <img src={profileImage} alt="Quique Martín Ocaña" className="profile-image"/>
          <div className="small-image-container right-1">
            <img src={smallImage3} alt="Small Image 3" className="small-image"/>
          </div>
          <div className="small-image-container right-2">
            <img src={smallImage4} alt="Small Image 4" className="small-image"/>
          </div>
        </div>
      </div>
      <h1>Quique Martín Ocaña</h1>
      <p>CenterED is an experimentation tool to measure the impact of AI-based tools in early stages of education.</p>
    </div>
  );
};

export default ProfileSection;