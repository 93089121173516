import React from 'react';
import './Home.css';
import ProfileSection from '../ProfileSection/ProfileSection';
import ProjectsSection from '../ProjectsSection/ProjectsSection';

function Home() {
    return (
        <div className="Home">
        <ProfileSection />
        <ProjectsSection />
        </div>
    );
}

export default Home;