import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const handleNavLinkClick = () => {
    // Check if the navigation is collapsed and the screen width is less than or equal to 768px
    if (isNavCollapsed && window.innerWidth <= 768) {
      setIsNavCollapsed(!isNavCollapsed);
    }
  };

  return (
    <>
      <div className={`overlay ${!isNavCollapsed ? 'show' : ''}`} onClick={handleNavCollapse}></div>
      <header className="header">
        <button className="nav-toggle" onClick={handleNavCollapse} aria-controls="navigation" aria-expanded={!isNavCollapsed}>
          <span>&#9776;</span>
        </button>
        <nav className={`navigation ${!isNavCollapsed ? 'collapsed' : ''}`} id="navigation">
          <Link to="/" className="nav-link" onClick={handleNavLinkClick}>Home</Link>
          <Link to="/resume" className="nav-link" onClick={handleNavLinkClick}>Resume</Link>
          <Link to="/related" className="nav-link" onClick={handleNavLinkClick}>Related</Link>
          <Link to="/contact" className="nav-link" onClick={handleNavLinkClick}>Contact</Link>
        </nav>
      </header>
    </>
  );
};

export default Header;
