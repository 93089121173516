import React from 'react';
import './Contact.css';
import { ReactComponent as TwitterIcon } from '../../icons/twitter.svg'; // Ensure you have an SVG file
import { ReactComponent as DiscordIcon } from '../../icons/discord.svg'; // Ensure you have an SVG file
import { ReactComponent as InstagramIcon } from '../../icons/instagram.svg'; // Ensure you have an SVG file
import { ReactComponent as LinkedInIcon } from '../../icons/linkedin.svg'; // Ensure you have an SVG file

const Contact = () => {
  return (
    <div className="contact-section">
      <h1>CONTACT</h1>
      <div className="social-media-links">
        <a href="https://twitter.com/martinocana" target="_blank" rel="noopener noreferrer">
          <TwitterIcon className="icon twitter" />
        </a>
        <a href="https://discord.com/users/signorecuicue" target="_blank" rel="noopener noreferrer">
          <DiscordIcon className="icon discord" />
        </a>
        <a href="https://www.instagram.com/quiquemartinn/" target="_blank" rel="noopener noreferrer">
          <InstagramIcon className="icon instagram" />
        </a>
        <a href="https://uk.linkedin.com/in/enriquemaoc/en" target="_blank" rel="noopener noreferrer">
          <LinkedInIcon className="icon linkedin" />
        </a>
      </div>
    </div>
  );
};

export default Contact;
