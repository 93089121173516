import React from 'react';
import './ProjectsSection.css';

const ProjectsSection = () => {
  return (
    <div className="projects-section">
      <div className="project-card">Centered</div>
      <div className="project-card">SanValenTwitch</div>
    </div>
  );
};

export default ProjectsSection;
